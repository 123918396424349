import type { ContentTypeCommunityStoryblok } from '@/types/storyblok'
import DeferredPromise from '@/utils/deferred-promise'
import type { ISbStoryData } from 'storyblok'

// The relation of the community to the current story
export type CommunityStoryRelation = 'self' | 'parent' | undefined

export const useCurrentStoryStore = defineStore('currentStory', () => {
  // we'll resolve this promise when the story is set
  // components that depend on the story can wait for this promise
  //
  // WARNING: NOT RESOLVING THIS PROMISE WILL CAUSE AN INFINITE LOADING STATE - ALWAYS CALL setStory() OR resolve this somehow!
  //
  const isReady = new DeferredPromise<boolean>()

  const story = ref<ISbStoryData | undefined>(undefined)
  const communityName = ref<string | undefined>(undefined)
  const communityContent = ref<ContentTypeCommunityStoryblok | undefined>(
    undefined,
  )
  const communityRelation = ref<CommunityStoryRelation>(undefined)
  const communitySlug = ref<string | undefined>(undefined)

  async function setStory(value: MaybeRefOrGetter<ISbStoryData>) {
    // Watch changes to original fetch response for Storyblok Bridge changes
    watch(
      () => toValue(value),
      () => {
        story.value = toValue(value)
      },
      { immediate: true },
    )

    await fetchCommunity()
    isReady.resolve(true)
  }

  // Type guard - community content
  const isCommunityContent = (
    content: ISbStoryData['content'] | undefined,
  ): content is ContentTypeCommunityStoryblok => {
    return content?.component === 'ContentTypeCommunity'
  }

  const setCommunityValues = (
    name: string | undefined,
    content: ContentTypeCommunityStoryblok | undefined,
    relation: CommunityStoryRelation,
    slug: string | undefined,
  ) => {
    communityName.value = name
    communityContent.value = content
    communityRelation.value = relation
    communitySlug.value = slug
  }

  async function fetchCommunity(): Promise<void> {
    communityContent.value = undefined

    if (isCommunityContent(story.value?.content)) {
      // if the story is a community, use its content
      setCommunityValues(
        story.value.name,
        story.value.content,
        'self',
        story.value.full_slug,
      )
    } else {
      // if the story not a community, try to fetch the community based on the route
      const path = useRoute().path
      // search the path using a regex for community slug, which will be at `*/communities/{state}/{communitySlug}/*`
      const matched = path.match(/communities\/([^/]+)\/([^/]+)/)
      const [_match, state, slug] = matched || []
      if (state && slug) {
        const communityData =
          await useStoryblokHelpers().fetchAsyncStory<ContentTypeCommunityStoryblok>(
            `communities/${state}/${slug}`,
          )
        if (communityData.value?.content) {
          setCommunityValues(
            communityData.value.name,
            communityData.value.content,
            'parent',
            communityData.value.full_slug,
          )
        }
      } else {
        // if no community is found, clear the values
        setCommunityValues(undefined, undefined, undefined, undefined)
      }
    }
  }

  function reset(): void {
    story.value = undefined
    communityContent.value = undefined
  }

  // Resolve the isReady promise on Nuxt Error so we don't have an infinite loading state
  const error = useError()
  watch(
    error,
    (val) => {
      if (val) {
        isReady.resolve(true)
      }
    },
    { immediate: true },
  )

  return {
    isReady: isReady.promise,
    story,
    communityName,
    communityContent,
    communityRelation,
    communitySlug,
    setStory,
    reset,
  }
})
